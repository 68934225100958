import $eventBus from '@/common/providers/eventbus';
import { getStaticPartnerId, partnerType } from '../../utilities/partnerType.js';

const isSSR = typeof window === 'undefined';

const requestQ = {};
function getTimezone() {
    return (0 - new Date().getTimezoneOffset() / 60).toFixed(1);
}
function _authticate(commit, key, trigger, lite, requestName, options, propertyData) {
    $eventBus.$emit('eventbus-signup', { trigger: trigger || '', lite: lite || false, requestName: requestName, options: options, propertyData });
    return new Promise(function (resolve, reject) {
        commit('updateLoginToDoList', {
            key: key,
            resolve: resolve,
            reject: reject,
        });
    });
}
function sendRequest({ commit, state }, payload) {
    if (!payload || !payload.url) {
        return Promise.reject('no api url');
    }

    const doAjax = (newData, newOpt) => {
        let opt = {
            url: payload.url,
            method: payload.method || 'POST',
            headers: payload.fetchheaders || {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            responseType: payload.responseType || 'json',
            timeout: payload.timeout || 4000000,
        };

        if (newOpt) {
            opt = Object.assign(opt, newOpt);
        }

        const abortController = opt.abortController || new AbortController();
        const timeout = setTimeout(() => abortController.abort(), opt.timeout);

        if (newData) {
            payload.data = Object.assign(payload.data, newData);
        }
        if (opt.method === 'GET') {
            if (payload.data) {
                let [u, p] = opt.url.split('?');
                opt.url = `${u}?${new URLSearchParams(payload.data).toString()}${p ? `&${p}` : ''}`;
            }
        } else {
            opt.data = payload.data;
        }
        const requestSuffix = state.pageType || 'any';
        const trigger = payload.data && payload.data.trigger;
        $eventBus.$emit(`eventbus-request-before-${requestSuffix}`, { req: opt, res: null, requestName: payload.requestName, trigger: trigger });
        if (state.isDevelopment && !isSSR) {
            $(document).trigger('request.send', payload);
        }
        const startTime = new Date();

        return fetch(opt.url, {
            method: opt.method,
            headers: opt.headers,
            body: opt.method !== 'GET' ? JSON.stringify(opt.data) : undefined,
            signal: abortController.signal,
        })
            .then((response) => {
                // Not 200-299
                if (!response.ok) {
                    // Reject the Promise with an error object similar to Axios
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then((res) => {
                if (payload.requestName) {
                    $eventBus.$emit(`eventbus-request-success-${requestSuffix}`, { req: opt, res: res, requestName: payload.requestName, trigger: trigger, startTime: startTime });
                }
                return res;
            })
            .catch((error) => {
                $eventBus.$emit(`eventbus-request-error-${requestSuffix}`, { req: opt, res: error, requestName: payload.requestName, trigger: trigger, startTime: startTime });
                return error;
            })
            .finally(() => {
                clearTimeout(timeout);
            });
    };
    if (payload.needLogin) {
        let userId = state.user.id;
        if (!userId) {
            return _authticate(commit, payload.url, payload.data.trigger, payload.data.lite, payload.requestName, payload.data.authOptions, payload.data.propertyData).then(function (data) {
                let newData = { ...data, userId: payload.data.userId || data.userId };
                return doAjax(newData);
            });
        }
        return doAjax({ userId: payload.data.userId || userId, userEmail: state.user.email || payload.data.email });
    }

    if (payload.keepSingle) {
        const singleAbortController = new AbortController();
        if (requestQ[payload.keepSingle]) {
            requestQ[payload.keepSingle].abort();
        }
        requestQ[payload.keepSingle] = singleAbortController;
        return doAjax(null, { abortController: singleAbortController });
    }

    return doAjax();
}

function searchByGeoPath({ dispatch, commit, state }, data) {
    const url = `${state.appUrl}widget/api/nearbyhomes/${data.path}`;
    let mutation = null;
    if (data.mutation) {
        mutation = data.mutation;
        delete data.mutation;
    }
    let geo = {
        pageType: state.pageType,
    };
    if (data.geo && data.geo.geoId) {
        geo.geoId = data.geo.geoId;
        geo.geoType = data.geo.geoType;
        geo.geoName = data.geo.geoName;
        geo.state = data.geo.state;
    }
    return dispatch('glb/sendRequest', { url: url, data: geo, method: 'GET', requestName: 'searchByPath' }, { root: true })
        .then((res) => {
            if (res && res.data && res.data.listings) {
                if (res.data.compliance) {
                    commit('glb/updateCompliance', res.data.compliance, { root: true });
                }
                if (mutation) {
                    commit(mutation, res.data.listings || []);
                }
                return Promise.resolve(res.data.listings || []);
            }
            return Promise.resolve([]);
        })
        .catch((err) => {
            return Promise.resolve([]);
        });
}

function getDppPotoByUrl({ dispatch, state }, data) {
    if (!data || !data.url) {
        throw new Error('parameter path is missing.');
    }
    const url = `${state.appUrl}api/v/property/photo/`;
    return dispatch('glb/sendRequest', { url: url, data: data, method: 'GET', requestName: 'property', keepSingle: 'property' }, { root: true }).then((res) => {
        if (res && res.data) {
            return Promise.resolve(res.data);
        }
        return Promise.resolve(null);
    });
}

function authenticate({ commit, state }, data) {
    if (state.user && state.user.id) {
        return Promise.resolve(state.user);
    }
    return _authticate(commit, data.key, data.trigger, data.lite, data.requestName, data.options || {});
}

function preCheckEmail({ dispatch, state }, data) {
    if (!data || !data.email) {
        throw new Error('Email is missing.');
    }
    const url = `${state.appUrl}/api/email/verify`;
    return dispatch('sendRequest', { url: url, data: data, method: 'GET', requestName: 'precheckEmail' }).then((res) => {
        if (res && res.data) {
            return Promise.resolve(res);
        }
        return Promise.reject(res);
    });
}

function preCheckPhone({ dispatch, state }, data) {
    if (!data || !data.phone) {
        throw new Error('phone number is missing.');
    }
    const url = `${state.appUrl}api/precheckphone/`;
    return dispatch('sendRequest', { url: url, data: data, method: 'GET', requestName: 'precheckphone' }).then((res) => {
        if (res && res.data) {
            return Promise.resolve(res);
        }
        return Promise.reject(res);
    });
}

function hasValidGeo(geo) {
    return geo && geo.address != 'Undisclosed' && geo.city && geo.state && geo.zipcode;
}
//update hotlead payload
function beforeSendHotlead(state, hotlead, opt) {
    let options = opt || {};

    if (state.user && state.user.email && state.user.fullName === state.user.email.split('@')[0] && state.user.fullName !== hotlead.name) {
        state.user.fullName = hotlead.name;
        hotlead.overwriteUser = true;
    }
    if (hotlead && hotlead.moveIn) {
        var date = new Date(hotlead.moveIn);
        if (date > 0) {
            var day = date.getDate(),
                month = date.getMonth() + 1,
                year = date.getFullYear();
            month = ('0' + month).slice(-2);
            day = ('0' + day).slice(-2);
            hotlead.moveIn = `${year}/${month}/${day}`;
        } else {
            hotlead.moveIn = '';
        }
    }
    if (options.propertyData) {
        //the price may update after hotlead inital, so only set price when user sumbit
        if (!hotlead.price && options.propertyData.price) {
            hotlead.price = options.propertyData.price;
        }
    }

    if (!state.isSSR) {
        hotlead.xLeadId = $('#leadid_token').val() || undefined;
    }
    const hotleadTypes = ['intentfulmortgagePRE'];
    if (hotlead.type && !hotleadTypes.includes(hotlead.type)) {
        hotlead.isValidEmail = true;
    } else {
        hotlead.isValidEmail = false;
    }
    if (state.split) {
        hotlead.split = state.split;
    }
    if (!hotlead.submission_attrs) {
        hotlead.submission_attrs = {};
    }
    if (!hotlead.submission_attrs.utm) {
        hotlead.submission_attrs.utm = {};
    }
    if (!hotlead.submission_attrs.mortgage) {
        hotlead.submission_attrs.mortgage = {};
    }
    if (state.utm_campaign) {
        hotlead.submission_attrs.utm.utm_campaign = state.utm_campaign;
    }
    if (state.utm_medium) {
        hotlead.submission_attrs.utm.utm_medium = state.utm_medium;
    }
    if (state.utm_content) {
        hotlead.submission_attrs.utm.utm_content = state.utm_content;
    }
    if (state.utm_source) {
        hotlead.submission_attrs.utm.utm_source = state.utm_source;
    }
    if (state.utm_term) {
        hotlead.submission_attrs.utm.utm_term = state.utm_term;
    }
    if (state.utm_campaign_operator) {
        hotlead.submission_attrs.utm.utm_campaign_operator = state.utm_campaign_operator;
    }
    if (state.utm_master_campaign) {
        hotlead.submission_attrs.utm.utm_master_campaign = state.utm_master_campaign;
    }

    if (options.isMovotoHomeLoans !== undefined) {
        if (options.isMovotoHomeLoans) {
            hotlead.submission_attrs.mortgage.mortgageIntent = true;
        } else {
            hotlead.submission_attrs.mortgage.mortgageIntent = false;
        }
    }

    if (options.isVeteran) {
        hotlead.submission_attrs.is_veteran = true;
    } else {
        hotlead.submission_attrs.is_veteran = false;
    }

    if (options.veteransType) {
        hotlead.veteransType = options.veteransType;
    } else {
        hotlead.veteransType = '';
    }
    if (hotlead.type) {
        const isSellLead = !!state.sellLeadType.find((item) => {
            return item.indexOf(hotlead.type) > -1;
        });
        const isDpp = state.pageType === 'vdpp';
        hotlead.needEmailAfterLead = !isSellLead && isDpp;
    }
}

function afterSendHotlead(dispatch, state, commit, hotlead, opt, resp) {
    let options = opt || {};
    const newUser = state.prevHotlead.options.newUser || resp.data.newUser;
    if (!state.isSSR) {
        $.setStorage('holeadInfo', {
            fullName: hotlead.name || '',
            phone: hotlead.phone || '',
            email: hotlead.email || '',
        });
        $.setStorage('newUser', newUser);
        $.setStorage('hotleadCount', resp.data.allHotleadCount);
    }

    commit('updatePrevHotlead', {
        trigger: options.trigger,
        container: options.container,
        sendHotleadType: hotlead.type,
        hotlead: { ...hotlead, hotleadId: resp.data.hotLeadId },
        hasSubmitLead: true,
        options: {
            ...options,
            hotleadCount: resp.data.allHotleadCount,
            newUser: newUser,
            hotleadId: resp.data.hotLeadId,
            hasOJOAgent: state.agent.id && !state.rentals,
            userType: resp.data.userType,
            isValidGeo: options.propertyData && hasValidGeo(options.propertyData.geo),
        },
    });
    if (hotlead.overwriteUser) {
        const url = `${state.appUrl}api/user/update/`;
        dispatch('sendRequest', {
            url: url,
            data: {
                fullname: hotlead.name,
                phone: state.user.phone || hotlead.phone,
                email: state.user.email || hotlead.email,
                userId: state.user.id,
            },
            method: 'POST',
        });
    }
}

function activateMovotoHomeLoansDripCampaign({ commit, dispatch, state }, data = {}) {
    const url = `${state.appUrl}api/email/movotohomeloans`;

    return dispatch('sendRequest', { url: url, data: data, method: 'POST', needLogin: !state.user.id, keepSingle: 'MHLdripcampaign' })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            return error;
        });
}

function sendHotlead({ commit, state, getters, dispatch }, { data, options }) {
    const url = `${state.appUrl}webservices/sendhotlead/`;
    beforeSendHotlead(state, data, options);
    const propertyId = data.propertyId;
    commit('updateUserHotleadInfo', {
        fullName: data.name,
        email: data.email,
        phone: data.phone,
    });

    return dispatch('sendRequest', { url: url, data: data, requestName: 'hotlead' })
        .then((res) => {
            if (res && res.status && res.status.code === 0 && res.data) {
                if (res.data.newUser) {
                    commit('updateUserInfo', {
                        fullName: data.name,
                        email: data.email,
                        phone: data.phone,
                        ...res.data,
                    });
                    $eventBus.$emit('eventbus-context-update');
                }
                if (propertyId) {
                    commit('addPropertyToSentHotleadList', propertyId);
                    if (state.user.id && data.isSaveFavoriteSplit) {
                        commit('updateFavoriteListings', { isFavorite: true, propertyId });
                    }
                }
                afterSendHotlead(dispatch, state, commit, data, options, res);
            }
            return res;
        })
        .catch((err) => {
            console.log(err);
            return Promise.reject(err);
        });
}

function sendZendeskRequest({ commit, state, getters, dispatch }, { data, options }) {
    const url = `${state.appUrl}api/hotlead/contactus/send`;
    beforeSendHotlead(state, data, options);
    commit('updateUserHotleadInfo', {
        fullName: data.name,
        email: data.email,
        phone: data.phone,
    });
    return dispatch('sendRequest', { url: url, data: data, requestName: 'zendesk' })
        .then((res) => {
            res.data = res.data || {};
            if (res && res.status && res.status.code === 0 && res.data) {
                afterSendHotlead(dispatch, state, commit, data, options, res);
            }
            return res;
        })
        .catch((err) => {
            console.log(err);
            return Promise.reject(err);
        });
}

function login({ commit, dispatch, state }, data) {
    const url = `${state.appUrl}webservices/login/`;
    //todo whether the timezone is required.
    data.timezone = getTimezone();
    return dispatch('sendRequest', { url: url, data: data, requestName: 'login' }).then((res) => {
        if (res && res.status && res.status.code === 0 && res.data && res.data.id) {
            commit('updateUserInfo', res.data);
            $eventBus.$emit('eventbus-context-update');
        }
        commit('runToDoList', { trigger: data.trigger });
        return res;
    });
}

function signup({ commit, dispatch, getters, state }, data) {
    const url = `${state.appUrl}webservices/signup/`;
    data.timezone = getTimezone();
    return dispatch('sendRequest', { url: url, data: data, requestName: 'signup' }).then((res) => {
        if (res && res.status && res.status.code === 0 && res.data && res.data.id) {
            commit('updateUserInfo', res.data);
            $eventBus.$emit('eventbus-context-update');
        }

        commit('runToDoList', { trigger: data.trigger });
        return res;
    });
}

function loginForGoogleAccount({ dispatch, commit, state }, data) {
    const url = `${state.appUrl}api/google/login/`;
    data.timezone = getTimezone();
    return dispatch('sendRequest', { url: url, data: data, method: 'GET', requestName: 'googlelogin' }).then((res) => {
        if (res && res.status && res.status.code === 0 && res.data && res.data.userId) {
            res.data.id = res.data.userId;
            commit('updateUserInfo', res.data);
            $eventBus.$emit('eventbus-context-update');
        }
        commit('runToDoList', { trigger: data.trigger });
        return res;
    });
}

function loginForAppleAccount({ dispatch, commit, state }, data) {
    const url = `${state.appUrl}api/apple/login/`;
    data.timezone = getTimezone();
    return dispatch('sendRequest', { url: url, data: data, method: 'GET', requestName: 'applelogin' }).then((res) => {
        if (res && res.status && res.status.code === 0 && res.data && res.data.userId) {
            res.data.id = res.data.userId;
            commit('updateUserInfo', res.data);
            $eventBus.$emit('eventbus-context-update');
        }
        commit('runToDoList', { trigger: data.trigger });
        return res;
    });
}

function oneTapGoogleLogin({ dispatch, commit, state }, data) {
    const url = `${state.appUrl}api/google/onetaplogin/`;
    data.timezone = getTimezone();
    return dispatch('sendRequest', { url: url, data: data, method: 'POST', requestName: 'onetapgooglelogin' }).then((res) => {
        if (res && res.status && res.status.code === 0 && res.data) {
            //the res.data will only include [clientId, client_id, credential, select_by, userType], most of data are google data which is not useful for us.
            //so after login by googlde login, we cannot inital user, and any task in runToDoList base on userInfo will failed
            if (res.data && res.data.id) {
                commit('updateUserInfo', res.data);
                $eventBus.$emit('eventbus-context-update');
            }
        }
        commit('runToDoList', { trigger: data.trigger });
        return res;
    });
}

function getAutoSuggestSavedSearch({ commit, dispatch, state }, data) {
    const url = `${state.appUrl}api/user/searchsuggestion/`;
    return dispatch('sendRequest', { url: url, data: data, method: 'GET' })
        .then((res) => {
            if (res && res.status && res.status.code === 0 && res.data) {
                commit('updateAutoSuggest', res.data);
            }
            return res;
        })
        .catch((error) => {
            commit('updateAutoSuggest', null);
            return error;
        });
}

function updateFavorite({ commit, dispatch, state }, data) {
    let actionType = data.isFavorite ? 'addfavorite' : 'removefavorite';
    window.addFavListingId = data.isFavorite && data.listingId;
    window.addFavPropertyId = data.isFavorite && data.propertyId;
    const url = `${state.appUrl}webservices/${actionType}/`;
    return dispatch('sendRequest', { url: url, data: data, needLogin: !state.user.id, requestName: actionType, keepSingle: 'favorite' })
        .then((res) => {
            commit('updateFavoriteListings', data);
            return res;
        })
        .catch((error) => {
            data.isFavorite = !data.isFavorite;
            commit('updateFavoriteListings', data);
            return error;
        });
}

function getFavoriteListings({ commit, state, dispatch }) {
    let userId = state.user.id;
    const url = `${state.appUrl}api/favoriteids/get/`;
    if (!userId) {
        return Promise.reject('login required');
    }

    return dispatch('sendRequest', {
        url: url,
        method: 'GET',
        data: {
            page: 1,
            size: 10000,
            sort: 'CITY',
            userId: userId,
        },
    }).then((res) => {
        if (res && res.data) {
            commit('updateFavoriteStore', res.data);
        }
        return res;
    });
}

function getSavedSearches({ commit, state, dispatch }) {
    let userId = state.user.id;
    if (!userId) {
        return Promise.reject('login required');
    }
    const url = `${state.appUrl}api/user/savedsearch/list/`;
    return dispatch('sendRequest', {
        url: url,
        method: 'GET',
        data: {
            page: 1,
            size: 10000,
            sort: 'CREATE_TIME',
            userId: userId,
        },
    }).then((res) => {
        if (res && res.data && res.data.savedSearches && res.data.savedSearches.length) {
            commit('updateSavedSearches', res.data.savedSearches);
        } else {
            commit('updateSavedSearches', []);
        }
        return res;
    });
}

function forgotPassword({ dispatch, state }, data) {
    const url = `${state.appUrl}webservices/findpassword/`;
    return dispatch('sendRequest', { url: url, data: data, requestName: 'findpassword' });
}

function addSaveSearch({ state, dispatch }, data) {
    const url = `${state.appUrl}api/user/savedsearch/add/`;
    data.lite = true;
    return dispatch('sendRequest', { url: url, data: data, needLogin: true, requestName: 'savedsearch' }).then(function (res) {
        if (res && res.data && state.user.id) {
            dispatch('getSavedSearches');
        }
        return res;
    });
}

function addDigsSubscribe({ state, commit, dispatch }, data) {
    const url = `${state.appUrl}api/digs/subscribe/`;
    data.lite = true;
    return dispatch('sendRequest', { url: url, data: data, needLogin: true, requestName: 'addDigsSubscribe' }).then(function (res) {
        if (res && res.data) {
            data.propertyId && commit('updateDigsSubscribe', data.userId + data.propertyId);
            return true;
        }
        return false;
    });
}

function addSaveSearchByEmail({ state, dispatch }, data) {
    const url = `${state.appUrl}api/user/savedsearch/addbyemail/`;
    return dispatch('sendRequest', { url: url, data: data, requestName: 'savedsearch' }).then(function (res) {
        if (res && res.status && res.status.code === 0 && res.data) {
            $eventBus.$emit('eventbus-context-update');
        }
        return res;
    });
}

function checkUserByEmailId({ state, dispatch }, data) {
    const url = `${state.appUrl}api/user/info/email/${data.email}/`;
    return dispatch('sendRequest', { url: url, data: data, method: 'GET' }).then(function (res) {
        return res;
    });
}

//this will be a global function about get per session infomation about client UI after static UI ready in client side
function getClientContext({ state, dispatch }) {
    const url = `${state.appUrl}/api/v/user/info/`;
    return dispatch('sendRequest', { url: url, method: 'GET' }).then(function (res) {
        return res;
    });
}

function sendPhoneOnlyLeadEmail({ commit, dispatch, state }, data) {
    const url = `${state.appUrl}api/email/phoneonlylead`;
    return dispatch('sendRequest', { url: url, data: data, requestName: 'sendPhoneOnlyLead' }).then((res) => {
        if (res && res.status && res.status.code === 0 && res.data && res.data.id) {
            $eventBus.$emit('eventbus-context-update');
        }
        return res;
    });
}

function sendUpgradeMovotoPremiumEmail({ commit, dispatch, state }, data) {
    const url = `${state.appUrl}api/email/upgrademovotopremium`;
    return dispatch('sendRequest', { url: url, data: data, requestName: 'sendUpgradeMovotoPremium' }).then((res) => {
        return res;
    });
}

function sendEmail({ commit, dispatch, state }, data) {
    const url = `${state.appUrl}api/email/tofriend/`;
    return dispatch('sendRequest', { url: url, data: data, requestName: 'shareToFriend' }).then((res) => {
        if (res && res.status && res.status.code === 0 && res.data && res.data.id) {
            $eventBus.$emit('eventbus-context-update');
        }
        return res;
    });
}

function claimBonus({ commit, dispatch, state }, data) {
    const url = `${state.appUrl}api/email/claimbonus/`;
    return dispatch('sendRequest', { url: url, data: data, requestName: 'claimbonus' }).then((res) => {
        if (res && res.status && res.status.code === 0 && res.data && res.data.id) {
            $eventBus.$emit('eventbus-context-update');
        }
        return res;
    });
}

function shareProfile({ commit, dispatch, state }, data) {
    const url = `${state.appUrl}service/agents/shareprofile/`;
    return dispatch('sendRequest', { url: url, data: data, method: 'POST' }).then((res) => {
        if (res && res.status && res.status.code === 0 && res.data && res.data.result == true) {
            $eventBus.$emit('eventbus-context-update');
        }
        return res;
    });
}

function fetchLender({ dispatch, state }, { hotleadId, phoneNumber, loopCount, apiName }) {
    apiName = apiName || 'mortgageAggregatorByConsumer';
    const url = `${state.appUrl}api/v/getlender/`;
    return dispatch('sendRequest', { url: url, data: { hotleadId: hotleadId, phoneNumber: phoneNumber, loopCount: loopCount, apiName: apiName }, method: 'GET' });
}

function getMortgageInfo({ commit, dispatch, state }, data) {
    const url = `${state.appUrl}api/mortgages/informa/v2/`;
    if (!data.utmcta) {
        data.utmcta = state.pageType;
    }
    return dispatch('sendRequest', { url: url, data: data, method: 'GET' }).then((res) => {
        if (res && res.data && res.data.rates) {
            res.data.rates.forEach((eachItem) => {
                let currentUrl = window.location.href;
                let displayTarget = 'movoto';
                eachItem.cpc = `${eachItem.cpc}&displayTargets[]=${displayTarget}&ref_url=${currentUrl}`;
            });
            return res.data;
        }
        return null;
    });
}

function getHousesBySearchUrl({ dispatch, state }, data) {
    if (!data || !data.path) {
        throw new Error('parameter path is missing.');
    }
    const url = `${state.appUrl}api/v/search/`;

    return dispatch('glb/sendRequest', { url: url, data: data, method: 'GET', requestName: 'search' }, { root: true }).then((res) => {
        if (res && res.data) {
            return res.data;
        }
        return {};
    });
}

function fetchBrokerageAgents({ commit, dispatch, state }, data) {
    const url = `${state.appUrl}api/v/agents/list/`;
    return dispatch('glb/sendRequest', { url: url, data: data, method: 'GET' }, { root: true }).then((res) => {
        if (res) {
            commit('updateBrokerageAgents', res.data || []);
            return res;
        }
        return [];
    });
}

function fetchPropertyByIds({ commit, getters, dispatch, state }, data) {
    const url = `${state.appUrl}api/v/property/propertyids/`;
    return dispatch('sendRequest', { url: url, data: data, method: 'POST' });
}

function fetchCachedMortgageRates({ commit, getters, dispatch, state }, data) {
    const url = `${state.appUrl}api/digs/fetchmortgagerates/`;
    return dispatch('sendRequest', { url: url, data: data, method: 'GET' }).then(function (res) {
        if (res) {
            return res;
        }
    });
}

function fetchMortgageRates({ commit, getters, dispatch, state }, data) {
    const url = `https://mortgage-api.digs.co/v1/historic`;
    return dispatch('sendRequest', { url: url, data: data, method: 'GET' });
}

function fetchRecentView({ commit, getters, dispatch, state }, data) {
    if (data && data.ids && data.ids.length) {
        data.ids = data.ids.slice(0, data.limit || 20);
    }
    const url = `${state.appUrl}api/v/property/propertyids?withCompliance=true`;
    return dispatch('sendRequest', { url: url, data: data, method: 'POST', requestName: 'recentView' }).then((res) => {
        if (res) {
            if (res.data && res.data.compliance) {
                commit('glb/updateCompliance', res.data.compliance, { root: true });
            }
            if (res.data && res.data.listings && res.data.listings.length > 0) {
                res.data.listings = res.data.listings.sort((a, b) => {
                    return data.ids.indexOf(a.propertyId) - data.ids.indexOf(b.propertyId);
                });
            }
            commit('updateRecentView', res.data.listings || []);
            return res.data.listings || [];
        }
        return [];
    });
}

// fetch nearby homes with given attribute
function fetchNearbyHomeFromAttribute({ commit, dispatch, state }, data) {
    const url = `${state.appUrl}api/v/property/attributenearbyhome?withCompliance=true`;
    return dispatch('sendRequest', { url: url, data: data, method: 'GET' }).then((res) => {
        if (res) {
            if (res.data && res.data.compliance) {
                commit('glb/updateCompliance', res.data.compliance, { root: true });
            }
            commit('updateNearbyHomeForAttribute', { data: res.data.listings || [], attributeTag: data.attributeTag });
            return res;
        }
        return [];
    });
}

async function findZipcode({ commit, getters, dispatch, state }, data) {
    if (!data) {
        return null;
    }
    const url = `${state.appUrl}api/geo/autosearch_v2/?key=${data}`;
    const res = await dispatch('sendRequest', { url: url, method: 'GET' });
    return res;
}

function fetchSurroundingListings({ commit, dispatch, state }, data) {
    const url = `${state.appUrl}api/v/search/listingsurroundsyou?withCompliance=true`;
    return dispatch('sendRequest', { url: url, data: data, method: 'GET' }).then((res) => {
        if (res) {
            if (res.data && res.data.compliance) {
                commit('glb/updateCompliance', res.data.compliance, { root: true });
            }
            commit('updateSurroundingListings', res.data.listings || []);
            return res;
        }
        return [];
    });
}

function fetchZipListByIP({ commit, dispatch, state }, data) {
    const url = `${state.appUrl}api/v/search/zipbyuserip/`;
    return dispatch('sendRequest', { url: url, data: data, method: 'GET' }).then((res) => {
        if (res && res.data) {
            return res.data;
        } else {
            return [];
        }
    });
}

function fetchAllZipListings({ commit, dispatch, state }, data) {
    if (!data || !data.zipcodePath) {
        return Promise.resolve(null);
    }
    const url = `${state.appUrl}api/v/search/zipcode/${data.zipcodePath}all/`;
    return dispatch('sendRequest', { url: url, data: data, method: 'GET' });
}

function fetchArticleFeeds({ commit, dispatch, state }, data) {
    if (!data) {
        return Promise.resolve(null);
    }
    let params = `_embed=author,wp:featuredmedia&per_page=${data.limit}`;
    data.category && (params += '&categories=' + data.category);
    data.tag && (params += '&tag=' + data.tag);
    const url = `https://www.movoto.com/blog/wp-json/wp/v2/posts/?${params}`;
    return dispatch('sendRequest', { url: url, method: 'GET' });
}
function fetchFAQs({ commit, dispatch, state }, data) {
    if (!data) {
        return Promise.resolve(null);
    }
    let params = `?limit=${data.limit || 10}`;
    data.query && (params += `&query=${data.query}`);
    const url = `${state.appUrl}api/v/articlefeeds/faqs/${params}`;
    return dispatch('sendRequest', { url: url, method: 'GET' });
}
function sentAppMessage({ commit, dispatch, state }, data) {
    if (!data || !data.phone) {
        return Promise.resolve(null);
    }
    const url = `${state.appUrl}api/sms/sendtextfreeapp/`;
    return dispatch('sendRequest', { url, method: 'POST', data });
}

function fetchMarketTrend({ commit, dispatch, state }, data) {
    if (!data) {
        return Promise.resolve(null);
    }
    const url = `${state.appUrl}api/market/trend/`;
    return dispatch('sendRequest', { url, method: 'GET', data }).then((res) => {
        if (res && res.status && res.status.code === 0 && res.data) {
            return res.data;
        }
        return null;
    });
}

function fetchJobs({ commit, dispatch, state }) {
    return dispatch('sendRequest', { url: 'https://boards-api.greenhouse.io/v1/boards/ojolabs/departments', method: 'GET' });
}
function fetchNearbySold({ commit, dispatch, state }, data) {
    const url = `${state.appUrl}api/v/property/nearbysold/`;
    return dispatch('sendRequest', { url: url, data: data, method: 'GET', requestName: 'nearbysold' }).then((res) => {
        if (res && res.data) {
            commit('updateNearbySold', res.data || []);
            return res.data;
        }
        return [];
    });
}

function fetchNearbySale({ commit, dispatch, state }, data) {
    const url = `${state.appUrl}api/v/property/nearbyforsale/`;
    return dispatch('sendRequest', { url: url, data: data, method: 'GET', requestName: 'nearbysale' }).then((res) => {
        if (res && res.data && res.data.listings) {
            commit('updateNearbySale', res.data.listings || []);
            return res.data;
        }
        return [];
    });
}

function getBoundaries({ commit, dispatch, state }, boundaryIndexId) {
    const url = `${state.boundaryUrl}${boundaryIndexId}`;
    return dispatch('sendRequest', { url: url, method: 'GET', fetchheaders: {} });
}

function addClaimedHome({ state, commit, dispatch }, data) {
    const url = `${state.appUrl}api/user/claimhome/`;
    data.lite = true;
    let needLogin = data.noLoginReq != 1;
    return dispatch('sendRequest', { url: url, data: data, needLogin: needLogin, requestName: data.requestName || 'addclaimhome' }).then(function (res) {
        if (res && res.data) {
            if (state.user.id) {
                dispatch('getClaimedHomes');
            }
            return true;
        }
        return false;
    });
}

function updateClaimedHome({ state, commit, dispatch }, data) {
    const url = `${state.appUrl}api/user/updateclaimhome/`;
    data.lite = true;
    let needLogin = data.noLoginReq != 1;
    return dispatch('sendRequest', { url: url, data: data, needLogin: needLogin, requestName: data.requestName || 'addclaimhome' }).then(function (res) {
        if (res && res.data) {
            commit('updateClaimedHomeById', res.data);
        }
        return res;
    });
}

function deleteClaimedHome({ state, commit, dispatch }, data) {
    const url = `${state.appUrl}api/user/claimedhome/remove`;
    return dispatch('sendRequest', { url: url, data: data, requestName: 'removeClaimedHome' }).then((res) => {
        if (res && res.data) {
            return res.data;
        }
    });
}

function getClaimedHomes({ commit, state, dispatch }, params = {}) {
    let userId = state.user.id;
    if (!userId) {
        return Promise.reject('login required');
    }
    const url = `${state.appUrl}api/user/claimedhomes/`;
    return dispatch('sendRequest', {
        url: url,
        method: 'GET',
        data: {
            page: params.page || 1,
            pageSize: params.pageSize || 10,
            userId: userId,
        },
        keepSingle: true,
    }).then((res) => {
        if (res && res.data) {
            commit('updateClaimedHomes', res.data);
        } else {
            commit('updateClaimedHomes', []);
        }
        return res;
    });
}

function fetchClaimedProperties({ commit, getters, dispatch, state }, data) {
    if (data && data.ids && data.ids.length) {
        data.ids = data.ids.slice(-(data.limit || 10));
    }
    const url = `${state.appUrl}api/v/property/propertyids/`;
    return dispatch('sendRequest', { url: url, data: data, method: 'POST' }).then((res) => {
        if (res && res.data) {
            commit('updateClaimedPropertiesList', res.data);
        } else {
            commit('updateClaimedPropertiesList', []);
        }
    });
}

function sendAgentJoinMail({ commit, dispatch, state }, data) {
    const url = `${state.appUrl}api/email/agentjoin/`;
    return dispatch('sendRequest', { url: url, data: data, requestName: 'agentJoin' }).then((res) => {
        return res;
    });
}

function sendAgentFeedback({ commit, dispatch, rootState }, data) {
    if (!data.userId || !data.agentId || !data.sentiment || !data.summary) {
        return Promise.reject('data required');
    }
    const url = `${rootState.glb.appUrl}api/v/user/submitagentfeedback/`;
    return dispatch('sendRequest', { url: url, data }).then((res) => {
        if (res && res.data) {
            return true;
        }
        return false;
    });
}
function fetchOpendoorData({ commit, dispatch, state }, data) {
    const url = `${state.appUrl}api/v/openddor/query`;
    return dispatch('sendRequest', { url: url, data }).then((res) => {
        if (res && res.data) {
            commit('updateOpendoor', res.data);
        }
        return res;
    });
}

function fetchNearbyHomesByLatLng({ commit, dispatch, rootState }, data) {
    if (!data) {
        return Promise.resolve(null);
    }
    const url = `${rootState.glb.appUrl}api/v/search/nearbyhomes?withCompliance=true`;
    return dispatch('glb/sendRequest', { url: url, data: data, method: 'POST' }, { root: true }).then((res) => {
        if (res) {
            if (res.data && res.data.compliance) {
                commit('glb/updateCompliance', res.data.compliance, { root: true });
            }
            commit('updateNearbyHomesLatLng', (res.data && res.data.listings) || []);
            return res;
        }
        return Promise.reject(res);
    });
}

function getGeoInfoBySearch({ commit, dispatch, state }, data) {
    const url = `${state.appUrl}api/common/geo/zipcode`;
    return dispatch('sendRequest', { url: url, data: data, method: 'GET' }).then((res) => {
        if (res && res.data) {
            return res.data;
        }
    });
}

function getAvmByPropertyId({ dispatch, state }, data) {
    const url = `${state.appUrl}api/v/property/avm/`;
    return dispatch('sendRequest', { url: url, data: data, method: 'GET' }).then((res) => {
        if (res && res.data) {
            return res.data;
        }
        return null;
    });
}

function fetchDpp({ dispatch, state }, data) {
    if (!data.url) {
        return;
    }
    const url = `${state.appUrl}api/v/property/info/?url=${data.url}`;
    return dispatch('sendRequest', { url: url, method: 'GET' }).then((res) => {
        if (res && res.data) {
            return res.data;
        }
        return null;
    });
}

async function getCpsProfileById({ dispatch, state }, { id, fetchRelationships = true }) {
    const url = `${state.appUrl}api/v/agent-profile-service/profile/${id}?fetchRelationships=${fetchRelationships}`;

    try {
        const result = await dispatch('sendRequest', { url, data: null, method: 'GET' });
        return result;
    } catch (err) {
        console.error(`An error occured attempting to fetch the CPS profile with id: ${movotoAgentCpsId}`, err);
        return null;
    }
}

async function getAgentProfilesByZip({ dispatch, state }, { zip, limit }) {
    const url = `${state.appUrl}api/v/agent-profile-service/profiles-by-zip/${zip}/?limit=${limit}`;

    try {
        const result = await dispatch('sendRequest', { url, data: null, method: 'GET' });
        return result;
    } catch (err) {
        console.error(`An error occured attempting to fetch agent profiles in the zip: ${zip}`, err);
        return null;
    }
}

async function getAssignedAgentProfile({ dispatch, state }, { movotoUserId }) {
    const url = `${state.appUrl}api/v/agent-profile-service/profile-assigned-to-movoto-user/${movotoUserId}`;

    try {
        return await dispatch('sendRequest', { url: url, data: null, method: 'GET' });
    } catch (err) {
        console.error(`An error occured attempting to fetch the agent profile assigned to the movoto user with id: ${movotoUserId}`, err);
        return null;
    }
}

async function emitAgentTouringAgreementViewedEvent({ dispatch, state }, { agentId, customerId }) {
    const url = `${state.appUrl}api/v/agent-profile-service/agent-touring-agreement-viewed/${agentId}/${customerId}`;

    try {
        return await dispatch('sendRequest', { url: url, data: null, method: 'GET' });
    } catch (err) {
        console.error(`An error occured attempting to emit the agent-touring-agreement-viewed event`, err);
        return null;
    }
}

async function emitAgentTouringAgreementAcceptedEvent({ dispatch, state }, { agentId, customerId }) {
    const url = `${state.appUrl}api/v/agent-profile-service/agent-touring-agreement-accepted/${agentId}/${customerId}`;

    try {
        return await dispatch('sendRequest', { url: url, data: null, method: 'GET' });
    } catch (err) {
        console.error(`An error occured attempting to emit the agent-touring-agreement-accepted event`, err);
        return null;
    }
}

async function getAgentTouringAgreementAcceptedEvent({ dispatch, state }, { agentId, customerId }) {
    const url = `${state.appUrl}api/v/agent-profile-service/get-agent-touring-agreement-accepted/${agentId}/${customerId}`;

    try {
        return await dispatch('sendRequest', { url: url, data: null, method: 'GET' });
    } catch (err) {
        console.error(`An error occured attempting to fetch the agent-touring-agreement-accepted event`, err);
        return null;
    }
}

async function getAgentTeamIdByAgentId({ dispatch, state }, { agentId }) {
    const url = `${state.appUrl}api/v/agent-profile-service/get-agent-team-by-agent-id/${agentId}`;

    try {
        return await dispatch('sendRequest', { url: url, data: null, method: 'GET' });
    } catch (err) {
        console.error(`An error occured attempting to fetch the agent team by agent id`, err);
        return null;
    }
}

function getCheckList({ commit, state, dispatch }, params = {}) {
    let userId = state.user.id;
    if (!userId) {
        return Promise.reject('login required');
    }
    const url = `${state.appUrl}api/user/ownerchecklist/`;
    return dispatch('sendRequest', {
        url: url,
        method: 'GET',
        data: {
            zipcode: params.zipcode,
            category: params.category,
        },
        keepSingle: true,
    }).then((res) => {
        if (res && res.data) {
            return res.data;
        }
        return null;
    });
}

function getCheckListPros({ commit, state, dispatch }, params = {}) {
    let userId = state.user.id;
    if (!userId) {
        return Promise.reject('login required');
    }
    const url = `${state.appUrl}api/user/checklistpros/`;
    return dispatch('sendRequest', {
        url: url,
        method: 'GET',
        data: {
            zipcode: params.zipcode,
            categoryPkID: params.categoryPkID,
        },
        keepSingle: true,
    }).then((res) => {
        if (res && res.data) {
            return res.data;
        }
        return null;
    });
}

function getMortagageData({ commit, state, dispatch }, params = {}) {
    let userId = state.user.id;
    if (!userId) {
        return Promise.reject('login required');
    }
    const url = `${state.appUrl}api/user/mortagagedata/`;
    return dispatch('sendRequest', {
        url: url,
        method: 'GET',
        data: {
            propertyId: params.propertyId,
        },
        keepSingle: true,
    }).then((res) => {
        if (res && res.data) {
            return res.data;
        }
        return null;
    });
}

function searchPlaces({ state, dispatch, commit }, data) {
    const url = `${state.appUrl}api/v/place/search/`;
    return dispatch('sendRequest', { url: url, data: data, method: 'POST' }).then((res) => {
        if (res?.data?.length) {
            commit('updatePlace', res.data);
            return res.data;
        }
        return null;
    });
}

function searchVideoByKeywords({ state, dispatch, commit }, keywords) {
    if (!keywords || !keywords.length) {
        return Promise.resolve(null);
    }
    const url = `${state.appUrl}api/v/video/search/`;
    return dispatch('sendRequest', {
        url: url,
        method: 'POST',
        data: {
            keywords: keywords,
        },
    }).then((res) => {
        if (res && res.data && res.data) {
            commit('updateVideo', res.data);
            return res.data;
        }
        return null;
    });
}

function searchVideoByIds({ state, dispatch, commit }, ids) {
    if (!ids || !ids.length) {
        return Promise.resolve(null);
    }
    const url = `${state.appUrl}api/v/video/detail/`;
    return dispatch('sendRequest', { url: url, method: 'POST', data: { ids: ids } }).then((res) => {
        if (res && res.data && res.data) {
            return commit('updateVideo', res.data);
        }
        return null;
    });
}

function mortgageSubmit({ state, dispatch }, params = {}) {
    const url = `${state.appUrl}api/v/mortgage/submit`;
    return dispatch('sendRequest', { url: url, data: params, method: 'POST', requestName: 'mortgageLead', keepSingle: 'mortgageSubmit' });
}

function getPropertyInfoById({ commit, dispatch, rootState }, data) {
    if (!(data.propertyIds && data.propertyIds.length)) {
        return null;
    }
    const url = `${rootState.glb.appUrl}api/v/property/listing/`;
    return dispatch('glb/sendRequest', { url: url, data: data, method: 'GET' }, { root: true }).then((res) => {
        if (res.data) {
            return res.data;
        }
    });
}

function getPartners({ commit, dispatch, state }, params) {
    const getPartnerIds = (params) => {
        const { listing, city, state, zipcode, language, mlsId, hotLeadValue } = params;

        const eligiblePartners = [
            {
                partnerName: 'Movoto',
                partnerKey: 'movoto',
                partnerType: 'provider',
                condition: 'fallback',
            },
        ];

        const mlsBasedPartners = {
            2001: {
                partnerName: 'Builders Digital Experience, LLC',
                partnerType: 'provider',
                partnerKey: partnerType.nhs,
            },
            4001: {
                partnerName: 'Zumper',
                partnerType: 'provider',
                partnerKey: partnerType.zumper,
            },
            4002: {
                partnerName: 'Apartment List',
                partnerType: 'provider',
                partnerKey: partnerType.apartment,
            },
        };

        /* MLS ID Specific Lead */
        if (Object.keys(mlsBasedPartners).includes(mlsId)) {
            eligiblePartners.unshift(mlsBasedPartners[mlsId]);
            return eligiblePartners;
        }

        if (hotLeadValue && hotLeadValue === 'veteranshublead') {
            eligiblePartners.unshift({
                partnerName: 'Veterans United Realty',
                partnerKey: 'veterans',
                partnerType: 'provider',
            });
            return eligiblePartners;
        }

        /* Veterans United */
        if (
            /* Veterans Hub Condition */
            !/(ChatBotBuy|ForceRegBuy)/.test(hotLeadValue) &&
            listing &&
            listing.price &&
            listing.price >= 75000 &&
            listing.price <= 600000 &&
            (!state || state !== 'NY') &&
            listing &&
            listing.propertyType &&
            !['LAND', 'MOBILE', 'MOBILE_HOME'].includes(listing.propertyType)
        ) {
            eligiblePartners.unshift({
                partnerName: 'Veterans United Realty',
                partnerKey: 'veterans',
                partnerType: 'provider',
                condition: {
                    property: 'isVeteran',
                    value: true,
                },
            });
        }
        const showCondition = eligiblePartners.find((item) => item.condition);

        /* Alliance */
        if (
            language &&
            language !== 'es' &&
            listing &&
            listing.price &&
            listing.price >= 125000 &&
            listing &&
            listing.propertyType &&
            !['RENTAL', 'ATTACHED', 'ALL', 'DETACHED', 'OTHER'].includes(listing.propertyType) &&
            !!city &&
            !!state
        ) {
            eligiblePartners.unshift({
                partnerName: 'Alliance Realty Network',
                partnerType: 'provider',
                partnerKey: 'alliance',
                condition: showCondition
                    ? {
                          property: 'isVeteran',
                          value: false,
                      }
                    : undefined,
            });
            return eligiblePartners;
        }

        /* Realtor */
        if (language && language === 'es' && city && state && zipcode) {
            eligiblePartners.unshift({
                partnerName: 'Realtor.com',
                partnerType: 'provider',
                partnerKey: 'realtor',
                condition: showCondition
                    ? {
                          property: 'isVeteran',
                          value: false,
                      }
                    : undefined,
            });
        }

        return eligiblePartners;
    };

    const partners = getPartnerIds(params);

    const fullyQualifiedPartners = partners.map((partner) => {
        return {
            ...partner,
            partnerId: getStaticPartnerId(partner.partnerKey, state.env),
        };
    });

    return Promise.resolve(fullyQualifiedPartners);
}

export default {
    getBoundaries,
    getDppPotoByUrl,
    authenticate,
    searchVideoByKeywords,
    searchPlaces,
    searchVideoByIds,
    getHousesBySearchUrl,
    getMortgageInfo,
    sendRequest,
    sendPhoneOnlyLeadEmail,
    sendUpgradeMovotoPremiumEmail,
    getPropertyInfoById,
    sendEmail,
    claimBonus,
    shareProfile,
    preCheckEmail,
    preCheckPhone,
    sendHotlead,
    login,
    signup,
    forgotPassword,
    loginForGoogleAccount,
    loginForAppleAccount,
    oneTapGoogleLogin,
    getAutoSuggestSavedSearch,
    updateFavorite,
    getFavoriteListings,
    getSavedSearches,
    addSaveSearch,
    addDigsSubscribe,
    addSaveSearchByEmail,
    checkUserByEmailId,
    getClientContext,
    searchByGeoPath,
    fetchDpp,
    fetchBrokerageAgents,
    fetchPropertyByIds,
    fetchMortgageRates,
    fetchRecentView,
    sendZendeskRequest,
    findZipcode,
    fetchSurroundingListings,
    fetchZipListByIP,
    fetchAllZipListings,
    fetchArticleFeeds,
    fetchFAQs,
    fetchJobs,
    sentAppMessage,
    fetchMarketTrend,
    fetchNearbySold,
    fetchNearbySale,
    fetchNearbyHomeFromAttribute,
    addClaimedHome,
    updateClaimedHome,
    getClaimedHomes,
    fetchClaimedProperties,
    deleteClaimedHome,
    sendAgentJoinMail,
    sendAgentFeedback,
    fetchOpendoorData,
    fetchNearbyHomesByLatLng,
    fetchLender,
    fetchCachedMortgageRates,
    getGeoInfoBySearch,
    getAvmByPropertyId,
    getCpsProfileById,
    getAgentProfilesByZip,
    getCheckList,
    getCheckListPros,
    getMortagageData,
    activateMovotoHomeLoansDripCampaign,
    mortgageSubmit,
    getAssignedAgentProfile,
    emitAgentTouringAgreementViewedEvent,
    emitAgentTouringAgreementAcceptedEvent,
    getAgentTouringAgreementAcceptedEvent,
    getAgentTeamIdByAgentId,
    getPartners,
};
